<template>
    <div id="about">
        <div id="about-box">
            <div id="about-close" @click="closeBox"></div>
            <div id="about-box-top"></div>
            <div id="about-box-bottom"></div>

            <perfect-scrollbar id="about-box-content" ref="scrollbar-text">

                <div v-if="getLang === 'de'" class="col-12 about-box-title text-center">Datenschutz</div>
                <div v-if="getLang === 'en'" class="col-12 about-box-title text-center">Data privacy </div>

                <div v-if="getLang === 'de'" class="col-12 abc-text " style="text-align: left">
                    Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutzgrundverordnung (DSGVO), ist: <br>
                    <br>
                    Sierra Madre GmbH<br>
                    Rohrstrasse 26<br>
                    D 58093 Hagen<br>
                    Vertreten durch:<br>
                    Guido Klaumann<br>
                    Timo Fischer<br>
                    Marcus da Costa<br>
                    <br>
                    <strong>Ihre Betroffenenrechte</strong><br>
                    <br>
                    Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende Rechte ausüben:<br>
                    <br>
                    – Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,<br>
                    – Berichtigung unrichtiger personenbezogener Daten,<br>
                    – Löschung Ihrer bei uns gespeicherten Daten,<br>
                    <br>
                    – Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,<br>
                    – Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und<br>
                    – Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben.<br>
                    <br>
                    Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.<br>
                    <br>
                    Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift finden Sie unter:<br>
                    <br>
                    <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</a><br>
                    <br>
                    <strong>Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</strong><br>
                    <br>
                    Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:<br>
                    <br>
                    – Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,<br>
                    – die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,<br>
                    – die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,<br>
                    <br>
                    die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben.<br>
                    <br>
                    <strong>Löschung bzw. Sperrung der Daten</strong><br>
                    <br>
                    Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.<br>
                    <br>
                    <strong>Erfassung allgemeiner Informationen beim Besuch unserer Website</strong><br>
                    <br>
                    Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person zulassen.<br>
                    <br>
                    Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu folgenden Zwecken verarbeitet:<br>
                    <br>
                    – Sicherstellung eines problemlosen Verbindungsaufbaus der Website,<br>
                    – Sicherstellung einer reibungslosen Nutzung unserer Website,<br>
                    – Auswertung der Systemsicherheit und -stabilität sowie<br>
                    – zu weiteren administrativen Zwecken.<br>
                    <br>
                    Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus den vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.<br>
                    <br>
                    Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren Internetauftritt und die dahinterstehende Technik zu optimieren.<br>
                    <br>
                    <strong>Cookies</strong><br>
                    <br>
                    Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine Textdateien, die von einem Websiteserver auf Ihre Festplatte übertragen werden. Hierdurch erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem und Ihre Verbindung zum Internet.<br>
                    <br>
                    Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer Webseiten ermöglichen.<br>
                    <br>
                    In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.<br>
                    <br>
                    Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Internet-Browser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die Verwendung von Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern können. Bitte beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.<br>
                    <br>
                    <strong>Kontaktformular</strong><br>
                    <br>
                    Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer validen E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen gemachten Angaben werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden personenbezogene Daten automatisch gelöscht.<br>
                    <br>
                    <strong>Newsletterdaten</strong><br>
                    <br>
                    Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und geben diese nicht an Dritte weiter.<br>
                    <br>
                    Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters können Sie jederzeit widerrufen, etwa über den “Austragen”-Link im Newsletter. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.<br>
                    <br>
                    Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.<br>
                    <br>
                    <strong>Social Media Auftritte</strong><br>
                    <br>
                    Wir unterhalten öffentlich zugängliche Profile in sozialen Netzwerken. Soziale Netzwerke wie Facebook, Instagram etc. können Ihr Nutzerverhalten in der Regel umfassend analysieren, wenn Sie deren Webseite oder eine Webseite mit integrierten Social-Media-Inhalten (z.B. Like-Buttons oder Werbebannern) besuchen. Durch den Besuch unserer Social-Media-Präsenzen werden zahlreiche datenschutzrelevante Verarbeitungsvorgänge ausgelöst. Im Einzelnen:<br>
                    <br>
                    Wenn Sie in Ihrem Social-Media-Account eingeloggt sind und unsere Social-Media-Präsenz besuchen, kann der Betreiber des Social-Media-Portals diesen Besuch Ihrem Benutzerkonto zuordnen. Ihre personenbezogenen Daten können unter Umständen aber auch dann erfasst werden, wenn Sie nicht eingeloggt sind oder keinen Account beim jeweiligen Social-Media-Portal besitzen. Diese Datenerfassung erfolgt in diesem Fall beispielsweise über Cookies, die auf Ihrem Endgerät gespeichert werden oder durch Erfassung Ihrer IP-Adresse.<br>
                    <br>
                    Mit Hilfe der so erfassten Daten können die Betreiber der Social-Media-Portale Nutzerprofile erstellen, in denen Ihre Präferenzen und Interessen hinterlegt sind. Auf diese Weise kann Ihnen interessenbezogene Werbung in- und außerhalb der jeweiligen Social-Media-Präsenz angezeigt werden. Sofern Sie über einen Account beim jeweiligen sozialen Netzwerk verfügen, kann die interessenbezogene Werbung auf allen Geräten angezeigt werden, auf denen Sie eingeloggt sind oder eingeloggt waren.<br>
                    <br>
                    Bitte beachten Sie außerdem, dass wir nicht alle Verarbeitungsprozesse auf den Social-Media-Portalen nachvollziehen können. Je nach Anbieter können daher ggf. weitere Verarbeitungsvorgänge von den Betreibern der Social-Media-Portale durchgeführt werden. Details hierzu entnehmen Sie den Nutzungsbedingungen und Datenschutzbestimmungen der jeweiligen Social-Media-Portale.<br>
                    <br>
                    <strong>Gewinnspiele und Wettbewerbe</strong><br>
                    <br>
                    Wir verarbeiten personenbezogene Daten der Teilnehmer von Gewinnspielen und Wettbewerben nur unter Einhaltung der einschlägigen Datenschutzbestimmungen, soweit die Verarbeitung zur Bereitstellung, Durchführung und Abwicklung des Gewinnspiels vertraglich erforderlich ist, die Teilnehmer in die Verarbeitung eingewilligt haben oder die Verarbeitung unseren berechtigten Interessen dient (z.B. an der Sicherheit des Gewinnspiels oder dem Schutz unserer Interessen vor Missbrauch durch mögliche Erfassung von IP-Adressen bei Einreichung von Gewinnspielbeiträgen).<br>
                    <br>
                    Falls im Rahmen der Gewinnspiele Beiträge der Teilnehmer veröffentlicht werden (z.B. im Rahmen einer Abstimmung oder Präsentation der Gewinnspielbeiträge bzw. der Gewinner oder der Berichterstattung zum Gewinnspiel), weisen wir darauf hin, dass die Namen der Teilnehmer in diesem Zusammenhang ebenfalls veröffentlicht werden können. Die Teilnehmer können dem jederzeit widersprechen.<br>
                    <br>
                    Findet das Gewinnspiel innerhalb einer Online-Plattform oder eines sozialen Netzwerks (z.B. Facebook oder Instagram, nachfolgend bezeichnet als “Online-Plattform“) statt, gelten zusätzlich die Nutzungs- und Datenschutzbestimmungen der jeweiligen Plattformen. In diesen Fällen weisen wir darauf hin, dass wir für die im Rahmen des Gewinnspiels mitgeteilten Angaben der Teilnehmer verantwortlich sind und Anfragen im Hinblick auf das Gewinnspiel an uns zu richten sind.<br>
                    <br>
                    Die Daten der Teilnehmer werden gelöscht, sobald das Gewinnspiel oder der Wettbewerb beendet sind und die Daten nicht mehr erforderlich sind, um die Gewinner zu informieren oder weil mit Rückfragen zum Gewinnspiel zu rechnen ist. Grundsätzlich werden die Daten der Teilnehmer spätestens 6 Monate nach Ende des Gewinnspiels gelöscht. Daten der Gewinner können länger einbehalten werden, um z.B. Rückfragen zu den Gewinnen beantworten oder die Gewinnleistungen erfüllen zu können; in diesem Fall richtet sich die Aufbewahrungsdauer nach der Art des Gewinns und beträgt z.B. bei Sachen oder Leistungen bis zu drei Jahre, um z.B. Gewährleistungsfälle bearbeiten zu können. Ferner können die Daten der Teilnehmer länger gespeichert werden, z.B. in Form der Berichterstattung zum Gewinnspiel in Online- und Offline-Medien.<br>
                    <br>
                    <strong>Verwendung von Google Analytics</strong><br>
                    <br>
                    Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. (folgend: Google). Google Analytics verwendet sog. „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.<br>
                    <br>
                    Die Zwecke der Datenverarbeitung liegen in der Auswertung der Nutzung der Website und in der Zusammenstellung von Reports über Aktivitäten auf der Website. Auf Grundlage der Nutzung der Website und des Internets sollen dann weitere verbundene Dienstleistungen erbracht werden. Die Verarbeitung beruht auf dem berechtigten Interesse des Webseitenbetreibers.<br>
                    <br>
                    Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">Browser Add On zur Deaktivierung von Google Analytics.</a><br>
                    <br>
                    Zusätzlich oder als Alternative zum Browser-Add-On können Sie das Tracking durch Google Analytics auf unseren Seiten unterbinden, indem Sie diesen Link anklicken. Dabei wird ein Opt-Out-Cookie auf Ihrem Gerät installiert. Damit wird die Erfassung durch Google Analytics für diese Website und für diesen Browser zukünftig verhindert, so lange das Cookie in Ihrem Browser installiert bleibt.<br>
                    <br>
                    <strong>Verwendung von Scriptbibliotheken (Google Webfonts)</strong><br>
                    <br>
                    Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser Website Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts (https://www.google.com/webfonts/). Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.<br>
                    <br>
                    Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten erheben.<br>
                    <br>
                    Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier: <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a> <br>
                    <br>
                    <strong>Verwendung von Google Maps</strong><br>
                    <br>
                    Diese Webseite verwendet Google Maps API, um geographische Informationen visuell darzustellen. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der Kartenfunktionen durch Besucher erhoben, verarbeitet und genutzt. Nähere Informationen über die Datenverarbeitung durch Google können Sie <a href="https://policies.google.com/privacy" target="_blank">den Google-Datenschutzhinweisen</a> entnehmen. Dort können Sie im Datenschutzcenter auch Ihre persönlichen Datenschutz-Einstellungen verändern.<br>
                    <br>
                    Ausführliche Anleitungen zur Verwaltung der eigenen Daten im Zusammenhang mit Google-Produkten <a href="https://support.google.com/accounts/answer/3024190" target="_blank">finden Sie hier.</a><br>
                    Facebook Pixel<br>
                    <br>
                    Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook. Dafür haben wir einen Code auf unserer Webseite implementiert. Der Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer Webseite erwerben, wird das Facebook-Pixel ausgelöst und speichert Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies. Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen. Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für uns anonym und nicht einsehbar und werden nur im Rahmen von Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind und angemeldet sind, wird der Besuch unserer Webseite automatisch Ihrem Facebook-Benutzerkonto zugeordnet.<br>
                    <br>
                    Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche und Interessen abgestimmt werden. So bekommen Facebook-User (sofern sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen. Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und eigenen Werbeanzeigen.<br>
                    <br>
                    Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen selbst verändern. Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf https://www.facebook.com/policy.php.<br>
                    <br>
                    <strong>Eingebettete YouTube-Videos</strong><br>
                    <br>
                    Auf einigen unserer Webseiten betten wir Youtube-Videos ein. Betreiber der entsprechenden Plugins ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine Seite mit dem YouTube-Plugin besuchen, wird eine Verbindung zu Servern von Youtube hergestellt. Dabei wird Youtube mitgeteilt, welche Seiten Sie besuchen. Wenn Sie in Ihrem Youtube-Account eingeloggt sind, kann Youtube Ihr Surfverhalten Ihnen persönlich zuzuordnen. Dies verhindern Sie, indem Sie sich vorher aus Ihrem Youtube-Account ausloggen.<br>
                    <br>
                    Wird ein Youtube-Video gestartet, setzt der Anbieter Cookies ein, die Hinweise über das Nutzerverhalten sammeln.<br>
                    <br>
                    Wer das Speichern von Cookies für das Google-Ad-Programm deaktiviert hat, wird auch beim Anschauen von Youtube-Videos mit keinen solchen Cookies rechnen müssen. Youtube legt aber auch in anderen Cookies nicht-personenbezogene Nutzungsinformationen ab. Möchten Sie dies verhindern, so müssen Sie das Speichern von Cookies im Browser blockieren.<br>
                    <br>
                    Weitere Informationen zum Datenschutz bei „Youtube“ finden Sie in der Datenschutzerklärung des Anbieters unter: https://www.google.de/intl/de/policies/privacy/<br>
                    <br>
                    <strong>Änderung unserer Datenschutzbestimmungen</strong><br>
                    <br>
                    Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.<br>
                    <strong>Fragen an den Datenschutzbeauftragten</strong><br>
                    <br>
                    Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in unserer Organisation:<br>
                    <br>
                    Dirk Friedhoff<br>
                    Business Protect<br>
                    Landweg 5 A<br>
                    58638 Iserlohn<br>
                    Telefon : 02371 – 8323204<br>
                    E-Mail : <a href="mailto:businessprotect@t-online.de">businessprotect@t-online.de</a> <br>
                    <br>
                    Nutzungsbedingungen von Inhalten und Mediadaten<br>
                    <br>
                    Datenschutzrechtliche Grundsätze<br>
                    Die Sierra Madre GmbH unterhält diese und andere Websites, um ihren Mitarbeitern, Partner-Agenturen, Handelspartnern und Gastronomen die Möglichkeit zu eröffnen, Texte, Bilder, Logos, Graphiken etc. rund um unsere Produkte zu erlangen. Das Herunterladen, die Vervielfältigung und Nutzung dieser Daten erfolgt eigenverantwortlich. Mit dem Herunterladen von Mediendaten erklären sie Ihr Einverständnis, an die nachfolgenden Nutzungsbedingungen gebunden zu sein:<br>
                    <br>
                    A. Keine Garantien<br>
                    Die Sierra Madre GmbH geht davon aus, dass die auf dieser Website zur Verfügung gestellten Mediendaten zum Zeitpunkt der Veröffentlichung richtig und vollständig sind. Wir prüfen und aktualisieren die bereitgestellten Mediendaten ständig. Die bereitgestellten Mediendaten stellen keine Zusicherung oder Garantien dar, weder ausdrücklich noch stillschweigend. Insbesondere stellen sie keine stillschweigenden Zusagen oder Garantien betreffend ihrer Richtigkeit, Aktualität, Beschaffenheit, Handelsfähigkeit, Eignung für bestimmte Zwecke oder den Nichtverstoß gegen Gesetze oder gewerbliche Schutzrechte dar.<br>
                    <br>
                    B. Rechte<br>
                    Alle Rechte an allen auf der Website zur Verfügung gestellten Mediendaten (insbesondere, aber nicht abschließend Texte, Bilder, Graphiken, Logos, Layouts, Design) liegen bei der Sierra Madre GmbH, bzw. kooperierenden, von uns beauftragten Unternehmen oder unseren Lizenzgebern. Dementsprechend dürfen Sie die bereitgestellten Mediendaten ohne unsere vorherige Zustimmung weder bearbeiten oder auf sonstiger Weise verändern noch derartige Bearbeitungen oder Änderungen veröffentlichen oder in sonstiger Weise verwerten. Entsprechendes gilt für Bearbeitungen und sonstige Änderungen an heruntergeladenen und reproduzierten Vervielfältigungsstücken bereitgestellter Mediendaten.<br>
                    <br>
                    C. Nutzung von Mediendaten<br>
                    Das Herunterladen, Ausdrucken und Verbreiten von Mediendaten ist Ihnen ausschließlich für Zwecke der Durchführung zulässiger Vertriebs- und Werbemaßnahmen für unsere Produkte und für private, nichtkommerzielle Zwecke gestattet. Jede darüber hinaus gehende Nutzung der Mediendaten bedarf der vorherigen Zustimmung der Sierra Madre GmbH.<br>
                    <br>
                    Wenn Sie sich Mediendaten von der Website herunterladen, verpflichten Sie sich, diese Mediendaten nur im hier genannten Rahmen zu nutzen. Hierzu gehört insbesondere, dass sich die von Ihnen durchgeführten Vertriebs- und Werbemaßnahmen nicht an Personen wenden, die das vorgeschriebene Mindestalter für den Konsum der beworbenen Produkte noch nicht erreicht haben.<br>
                    <br>
                    D. Drucktechnische Reproduzierbarkeit<br>
                    Die auf der Website zur Verfügung gestellten Mediendaten wurden zum Teil auch für die drucktechnische Reproduzierbarkeit angelegt. Die Druckqualität kann trotz diverser Optimierung durch drucktechnische Variablen (Druckverfahren, Papier, Farbe, etc.) beeinflusst werden, auf die wir keinen Einfluss haben. Eine Prüfung der zur Verfügung gestellten Materialien wird von unserer Seite daher unbedingt vor dem Druck empfohlen.<br>
                    <br>
                    E. Haftungsausschluss<br>
                    Die Haftung für Kosten oder Schäden (unmittelbare oder mittelbare, direkt oder auf sonstige Weise verursacht), die auf Grund der Nutzung der bereitgestellten Mediendaten verursacht worden sind, setzt vorsätzliches oder grob fahrlässiges Verhalten unsererseits oder einer unserer gesetzlichen Vertreter oder Erfüllungsgehilfen voraus. In diesem Fall ist unsere Haftung gegenüber Kaufleuten auf den typischerweise vorhersehbaren Schaden begrenzt.<br>
                    <br>
                    Eine Haftung für leichte Fahrlässigkeit besteht nur bei der Verletzung wesentlicher Vertragspflichten. In diesem Fall ist unsere Haftung stets auf den typischen vorhersehbaren Schaden begrenzt. <br>
                    <br>
                    Wir übernehmen keine Haftung für eine etwaige mangelnde Druckqualität, die auf die Einflussnahme drucktechnischer Variablen zurückgeht. Des Weiteren übernimmt die Sierra Madre GmbH keine Haftung für die Qualität von Druckausgaben, für die die zur Verfügung gestellten Mediendaten nicht optimiert wurden.Für Kosten oder Schäden, die Dritten auf Grund einer Nutzung der Mediendaten entstanden sind, übernehmen wir ebenfalls keinerlei Haftung.<br>
                    <br>
                    Die Datenschutzerklärung wurde mit dem Datenschutzerklärungs-Generator der activeMind AG erstellt.

                </div>

                <div v-if="getLang === 'en'" class="col-12 abc-text " style="text-align: left">
                    The responsible authority according to the data protection acts, especially according to the General Data Protection Regulation (GDPR) within the European Union, is: <br>
                    <br>
                    Sierra Madre GmbH<br>
                    Rohrstrasse 26<br>
                    D-58093 Hagen<br>
                    Vertreten durch:<br>
                    Guido Klaumann<br>
                    Timo Fischer<br>
                    Marcus da Costa<br>
                    <br>
                    <strong>Rights of the persons affected</strong><br>
                    <br>
                    Using the given contact information of our data protection officer, you can at any given time make use of the following rights:<br>
                    <br>
                    – requesting of information on which of your personal data is saved and how it is processed,<br>
                    – correction of incorrect personal data,<br>
                    – deletion of your personal data saved by us,<br>
                    <br>
                    – restriction on data procession, in case we are legally not allowed to delete your personal data yet,<br>
                    – objection to the processing of your personal data by us, and<br>
                    – data portability, in case you have consented to data processing or have entered into a legal agreement with us.<br>
                    <br>
                    Provided that you have given us your consent, you can at any time revoke your consent for the future.<br>
                    <br>
                    You can at any given time contact the responsible regulatory authority with a complaint. Your responsible regulatory authority depends on the federal state of your registered place of residence, your workplace or the suspected infringement. A list of all regulatory authorities (for the non-public sector) including addresses can be found here:
                    <br>
                    <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</a><br>
                    <br>
                    <strong>Purposes of data processing by the responsible authority and by third parties</strong><br>
                    <br>
                    We process your personal data exclusively for the purposes stated in this privacy policy. There is no passing on of your personal data to third parties, apart from the purposes stated herein. We only pass on your personal data to third parties if:
                    <br>
                    – you have expressly given us your consent to do so,<br>
                    – such disclosure is necessary in order to enter into a legal agreement with you,<br>
                    – such disclosure is necessary for compliance with a legal obligation,<br>
                    <br>
                    such disclosure is necessary to safeguard justified interest and there is no reason for assuming that you might be having an overriding interest warranting the protection and non-disclosure of your personal data.
                    <br>
                    <strong>Deletion or blocking of data</strong><br>
                    <br>
                    We adhere to the principles of data avoidance and data economy. Accordingly, we only save your personal data for as long as we might need them to achieve the purposes stated here or for as long as any of the retention periods laid down by the legal authorities necessitate it. After the ending of such a purpose or the expiration of such periods, the relevant data is blocked or deleted routinely and according to the legal requirements.
                    <br>
                    <strong>Collection of general information during a visit of our website</strong><br>
                    <br>
                    When you visit our website, cookies enable us to automatically collect general information. These server log files contain information such as the type of web browser used, the operating system, the domain name of your internet service provider, and similar data. The collected data exclusively contains information that does not allow any conclusions being drawn about your person.
                    <br>
                    This information is technically necessary in order to deliver site content requested by you and it is required for the use of the internet. It is especially processed for the following purposes:
                    <br>
                    – to ensure easy connectivity and seamless loading of the website,<br>
                    – to ensure the trouble-free use of our website,<br>
                    – to evaluate the security and stability of the system, and<br>
                    – zu weiteren administrativen Zwecken.<br>
                    <br>
                    Our processing of your personal data is based on our legitimate interest in the aforementioned purposes for data collection. We do not use your data to come to any conclusions about your person. The data is received only by the responsible bodies and if necessary by their contractors.
                    <br>
                    Anonymous information of this kind might be statistically evaluated by us if necessary, in order to optimize our web presence as well as the technology behind it.<br>
                    <br>
                    <strong>Cookies</strong><br>
                    <br>
                    Like many other websites, we also use so-called "cookies". Cookies are small amounts of data that are transmitted to your computer’s hard disk by a server. This automatically provides us with certain information, such as for example your IP address, the used browser, the operating system, and your connection to the internet.
                    <br>
                    Cookies cannot be used to start programs or infect a computer with a virus. The information collected through cookies enables us to make navigation easier for you and to display the websites correctly for you.
                    <br>
                    Under no circumstances do we pass on such collected data to third parties or connect them to your personal data without your express consent.
                    <br>
                    It is of course possible for you to access a basic version of our website without cookies. Generally, internet browsers are configured to accept cookies. It should be possible for you to deactivate the use of cookies in your browser’s settings. Please consult the help options of your browser in order to find out how to change the settings. And please note that some features of our website might not work properly if you disable cookies.
                    <br>
                    <strong>Contact</strong><br>
                    <br>
                    In the event that you contact us with any kind of question via email or via our contact form, you consent to your personal data being used for the purpose of contacting you. This necessitates providing us with a valid email address. It is necessary to assign the contact request and to reply to it. Providing us with any additional personal information is optional. The data provided by you is saved for the purpose of processing of your request as well as for possible follow-up questions. Your personal data is deleted automatically as soon as your issue has been solved.
                    <br>
                    <strong>Newsletter data</strong><br>
                    <br>
                    If you want to subscribe to the newsletter offered on the website, we need your email address as well as other relevant information that allows us to verify that you are indeed the unser of the given email account and have agreed to receiving the newsletter. Further data is not collected or only provided by you on a voluntary basis. This data is used exclusively for sending out the requested information, and it is not passed on to any third parties.
                    <br>
                    The data entered into the subscription form for the newsletter is processed exclusively on the basis of your consent (Article 6, paragraph 1 a GDPR). At any given time, you can revoke your consent to us saving the data and the email address as well as using them to send out the newsletter; you can do so by clicking on the "unsubscribe" link in the newsletter, for example. The legitimacy of previously processed data shall remain unaffected by your revocation.
                    <br>
                    The data provided by you in order to receive the newsletter is saved by us until such time when you unsubscribe from the service; it is deleted upon your unsubscription. Data that has been saved by us for other purposes (such as, for example, email addresses for memberships and user accounts) shall remain unaffected by this.
                    <br>
                    <strong>Social Media Presences</strong><br>
                    <br>
                    We maintain publicly accessible presences on social media networks. Social media such as Facebook, Instagram, etc. are usually able to comprehensively analyze your user behaviour if you visit their website or a website with integrated social media content (such as, for example, like buttons or advertising banners). By visiting our social media presences, numerous data protection related processes are initialized. They are as follows:
                    <br>
                    If you are logged in to your social media account and visit our social media presence, the provider of such social media platforms is able to assign the visit to your user account. Furthermore, it is under certain circumstances possible that your personal data can be collected even in the event that you are not logged in or that you do not have a user account registered with the relevant social media platform. Such a collecting of data might, for example, be achieved via cookies that are saved on your device or by being derived from your IP address.
                    <br>
                    By processing such collected data, the providers of the social media platforms are able to create user profiles which store your preferences and interests. In doing so, they are able to show you interest-related advertising on or off the relevant social media presence. If you have registered a user account with the relevant social media network, such interest-related advertising can be displayed on all devices on which you were or are logged in.
                    <br>
                    Please note furthermore that we are unable to comprehend all data processing carried out on such social media platforms. Depending on the provider, there might be further processing of your data by those providers of social media platforms. Details can be obtained by reading the respective terms of use and privacy policies of those social media platforms.
                    <br>
                    <strong>Prize draws, giveaways, and contestse</strong><br>
                    <br>
                    We process personal data of persons taking part in prize draws, giveaways and contests exclusively in accordance with the relevant data policies, insofar as their processing is a contractual necessity for providing, hosting and carrying out such events, the contestant has consented in the processing, or such processing serves our vested interest (such as, for example, for the purpose of making the event secure or protecting our interests from abuse through possible collection of IP addresses while submitting entries into the prize draw, giveaway or contest).
                    <br>
                    In the event that posts of the participants are made public as part of the contests (such as, for example, as part of a voting process or a presentation of the winning entries or the winners or news on the contest), we would like to point out the possibility that the participants’ names might also be made public. All participants reserve the right to dispute this at any given time.
                    <br>
                    If the prize draw, giveaway or contest is organized within an online platform or a social media network (such as, for example, Facebook or Instagram, hereinafter referred to as "online platform"), additional terms of use and privacy policies of these online platforms apply. In such events, we shall notify the participants that we are responsible for any participant’s data provided as part of the contest and that any pertaining questions are to be directed at us.
                    <br>
                    The participants’ data shall be deleted as soon as the prize draw, giveaway or contest has ended and the data is not needed anymore in order to inform the winners or to handle expected queries regarding the contest. In principle, the participants’ data shall be deleted at the latest 6 months after the end of the contest. It may be stored for longer in order to, for example, reply to pertaining questions or comply with the winning obligations; in this event, the information retaining period depends on the kind of win and extends to, for example, up to three years for objects or services in oder to, among other things, deal with warranty claims. Furthermore, the participants’ data may be stored for longer in oder to, for example, provide news updates on the contest in online and offline media channels.
                    <br>
                    <strong>Use of Google Analytics</strong><br>
                    <br>
                    This website uses Google Analytics, a web analysis service provided by Google Inc. (hereinafter referred to as Google). Google Analytics uses so-called "cookies": small data files that are saved on your computer and that allow the analysis if your usage of the website. The information created by the cookies regarding your usage of the website is generally passed on to a Google server in the USA and stored there. As per the activation of IP anonymization on these websites, Google will - within member countries of the European Union or within other contracting parties to the agreement on the European Economic Area - shorten your IP address before passing it on. Only in exceptional cases will your full IP address be passed on to a Google server in the USA and shortened thereafter. On behalf of the provider of this website, Google will use such information to analyse your usage of the website, to create reports on your activity on the website, and to offer further services to the website provider that are connected to the website usage and internet usage. The IP address relied by your browser to Google Analytics shall not be connected to any other data collected by Google.
                    <br>
                    The purpose of processing such data lies in evaluating the usage of the website and in creating reports on activities on the website. On the basis of the website usage and internet usage, further services shall then be offered. Such processing of data lies in the vested interest of the website provider.
                    <br>
                    You can prevent the storing of cookies with an appropriate change of your browser settings; we would however like to point out that in that case, it may not be possible to make full use of all website features. You can furthermore prevent the collecting and passing on of the data created by the cookie and of the data related to your website usage (including your IP address) to Google as we as its further processing by Google, by downloading and installing the browser extension obtainable by clicking on the following link: browser add-on for the deactivation of Google Analytics.
                    <br>
                    In addition to or as an alternativ to this browser add-on, you can prevent the Google Analytics tracking on our websites by clicking on this link. This will install an opt-out cookie on your device. This shall prevent Google Analytics from collecting data on this website and for this browser for as long as the opt-out cookie remains installed on your browser.
                    <br>
                    <strong>Use of script libraries (Google Webfonts)</strong><br>
                    <br>
                    In order to display our content correctly and in a graphically appealing way, our website uses script libraries and font libraries such as, for example, Google Webfonts (https://www.google.com/webfonts/). Google Webfonts is transferred to your browser cache in order to prevent multiple loading. In the event that your browser does not support Google Webfonts or prevents its access, our content will be displayed in a standard font.
                    <br>
                    Accessing script libraries and font libraries will automatically establish a connection to the library provider. This might theoretically enable the library provider to collect library-relevant data - however, it is currently unclear if and for what purpose this occurs.
                    <br>
                    Google’s privacy policy regarding such libraries can be found here: <a href="https://www.google.com/policies/privacy/" target="_blank">https://www.google.com/policies/privacy/</a> <br>
                    <br>
                    <strong>Use of Google Maps</strong><br>
                    <br>
                    This website uses Google Maps API in order to visualize and display geographic information. The usage of Google Maps is connected to Google collecting, processing and using information about the usage of the map function by the visitors of the website. Further information on Google’s processing of such data can be obtained by reading the relevant privacy policy provided by Google. Their data protection centre should also allow you to change your personal privacy settings.
                    <br>
                    For more detailed instructions on how to manage the use of your personal data by Google-provided services and products can be <a href="https://support.google.com/accounts/answer/3024190" target="_blank">found here.</a>
                    <br>
                    <strong>Facebook Pixel</strong><br>
                    <br>
                    On our website, we use the Facebook Pixel provided by Facebook. Accordingly, we have implemented the respective code on our website. This Facebook Pixel consists of a segment of JavaScript code that loads a collection of functions with which Facebook can follow up on your user actions insofar as you have encountered any Facebook advertising on our website. If, for example, you buy a product on our website, it will activate the Facebook Pixel and save your action on our website in one or more than one cookie. These cookies enable Facebook to compare your user data (such as IP address, user ID) with the data provided on your Facebook account. Facebook will then delete this data. Such collected data is anonymous and not visible to us, and it is only usable within the frame of placing advertisements. If you are a logged in Facebook user, visiting our website will automatically be assigned to your Facebook account.                    <br>
                    We want to display our services and/or products only to those who are really interested in them. The Facebook Pixel helps us to better tailor our advertising measures to your interests and needs. This way, Facebook users (who have allowed personalized ads) get to see advertisements that are relevant to them. Furthermore, Facebook uses the collected data for its own analyses and ads.
                    <br>
                    If you are registered with Facebook angemeldet sind, you can personally change the settings for personalized advertisements here: https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen selbst verändern. Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf https://www.facebook.com/policy.php.<br>
                    <br>
                    <strong>Embedded YouTube videos</strong><br>
                    <br>
                    We embed YouTube videos on some of our websites. These plug-ins are provided by the company YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. When you visit a site with a YouTube plug-in, a connection is being made to the YouTube servers, during which YouTube is notified which sites you are visiting. If you are logged in to your YouTube account, YouTube is able to assign your surfing behaviour to your user profile. You can prevent this by logging out of your YouTube account prior to accessing the website.
                    <br>
                    If you start watching a YouTube video, the provider will use cookies in order to collect information on your user behaviour.<br>
                    <br>
                    If you have deactivated the storing of cookies by Google Ads, then you won’t have to deal with any such cookies while watching YouTube videos. However, YouTube also stores non-personal usage data in other types of cookies. If you would like to prevent this, you have to block the storing of cookies in your browser.                    <br>
                    More information on YouTube’s privacy policy can be found in the provider’s data protection regulations here: https://www.google.de/intl/de/policies/privacy/<br>
                    <br>
                    <strong>Changes to our privacy policy</strong><br>
                    <br>
                    We reserve the right to adjust this privacy policy in order to meet all and any currently valid legal requirements or in order to implement changes to our services in our data protection regulations, for example as part of the introduction of new services. This new privacy policy will then apply to your next visit.
                    <br>
                    <strong>Questions for the data protection officer</strong><br>
                    <br>
                    In the event of questions regarding our privacy policy, please send us an email or directly get in touch with the person responsible for data protection in our organization:<br>
                    <br>
                    Dirk Friedhoff<br>
                    Business Protect<br>
                    Landweg 5 A<br>
                    D-58638 Iserlohn<br>
                    Phone : 02371 – 8323204<br>
                    Email : <a href="mailto:businessprotect@t-online.de">businessprotect@t-online.de</a> <br>
                    <br>
                    Terms of use for content and media files<br>
                    <br>
                    General principles of data protection<br>
                    Sierra Madre GmbH maintains this and other websites in order to provide its employees, partner agencies, trading partners and gastronomers with the opportunity of obtaining texts, pictures, logos, graphics, etc. pertaining to our products. Downloading, reproducing and using this data is each person’s own responsibility. By downloading media files, you agree to being bound by the following terms of use:                    <br>
                    A. No guarantees<br>
                    Sierra Madre GmbH understands that the media files provided on this website are accurate and complete at the time of making them publicly available. We shall constantly check these media files and keep them up to date. The media files provided here do not in any form serve as a guarantee or warranty. They especially do not serve in any form as implied guarantees or warranties with regards to their accuracy, up-to-dateness, condition, merchantability, suitability for a specific purpose or non-infringement of law and patents.
                    <br>
                    B. Rights<br>
                    All rights to the media files provided on the website (especially but not limited to, texts, pictures, graphics, logos, layouts, design) lie with Sierra Madre GmbH or if applicable with companies cooperating with us, commissioned by us, or our licensors. Accordingly, you are not allowed to edit or in any other way alter such provided media files or publish such edits or changes or utilize them in any other way. The same applies to editing or in any other way altering provided media files that have been downloaded or replicated.                    <br>
                    C. Use of media files<br>
                    Downloading, printing and processing the media files provided by us is permitted only for the purpose of implementing permissible sales or marketing campaigns for our products and for private, non-commercial purposes. Any use of the media files over and above these purposes necessitates prior approval by Sierra Madre GmbH.
                    <br>
                    By downloading media files from the website, you are obliged to use them only for the purposes mentioned herein. This especially includes ensuring that the sales and marketing campaigns undertaken by you do not target persons that have not attained the minimum legal age for the consumption of the advertised products.
                    <br>
                    D. Reproducibility in print<br>
                    Some of the media files provided on the website are suitable for being reproduced in print. However, the printing quality can, in spite of ensuring its optimization, be influenced by technical variables (such as, for example, printing method, paper, ink, etc.) which we cannot control. Therefore, we definitely recommend checking the provided material for its printability before printing it.
                    <br>
                    E. Disclaimer<br>
                    Liability for costs or damages (actual or potential, directly or indirectly caused in any way) arising out of the use of the provided media files necessitates willful intent or gross negligence on our part or on the part of one of our legal representatives or auxiliary persons. In that event, our liability towards merchants is typically limited to foreseeable damage.
                    <br>
                    Liability for slight negligence only applies in the case of a breech of essential contractual obligations. In that event, our liability is always limited to typical foreseeable damages.
                    <br>
                    We shall assume no liability for possible lack of printing quality that can be traced back to the influence of technical variables while printing. Furthermore, Sierra Madre GmbH shall not be liable for the quality of print-outs for which the media files provided by us were not optimized prior to printing. And we shall furthermore not be liable for any costs or damages to third parties that arise out of the use of the provided media files.
                    <br>
                    This privacy policy statement was created with the help of the privacy policy generator provided by activeMind AG.

                </div>

            </perfect-scrollbar>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "DatenschutzView",
        metaInfo: {
            title: 'Datenschutz',
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});
        },
        computed: {
          ...mapGetters(['getLang'])
        },
        methods: {
            closeBox(){
                this.$router.push({ name: ( this.$store.getters.getLang === 'de' ? 'Store' : 'StoreENG' ) } );
            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        }
    }
</script>

<style lang="less" >

</style>
